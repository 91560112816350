import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SessionListenerService } from '../session-listener/session-listener.service';

@Injectable()

export class SpinnerService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  apiQueue: number[] = [];

  constructor(private _sLS: SessionListenerService){}

  show() {
    this.apiQueue.push(1);
    this.isLoading.next(true);
    this._sLS.manualStopListening();
  }

  hide() {
    this.apiQueue.pop();
    this.isLoading.next( this.apiQueue.length != 0 );
    if(this.apiQueue.length == 0) this._sLS.manualStartListening();
  }
}
