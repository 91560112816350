import { Injectable, NgZone } from '@angular/core';
import { fromEvent, map, merge, Observable, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { AuthService } from '../auth.service';
import { environment as ENV } from 'environments/environment';

@Injectable()
export class SessionListenerService {
  private _userActivitySubscription: Subscription | undefined;
  private _stopListening$ = new Subject<void>();
  private _isModalConfirm: boolean = false;
  private _extendTimeout: any;

  constructor(private ngZone: NgZone,
              private _authS: AuthService) {
            this.manualStartListening(); 
  }

  startWatching() {   
    const timeout = ENV.sessionLiveTime;
    this.ngZone.runOutsideAngular(() => {
      const mouseMove$ = fromEvent(document, 'mousemove').pipe((map( () => 'mousemove')));
      const scroll$ = fromEvent(document, 'scroll').pipe((map( () => 'scroll')));
      const click$ = fromEvent(document, 'click').pipe((map( () => 'click')));
      const keydown$ = fromEvent(document, 'keydown').pipe(map( () => 'keydown'));
      const activity$ = merge(mouseMove$, scroll$, click$, keydown$);
      this._userActivitySubscription = activity$
        .pipe(
          switchMap(() => new Observable(observer => {
              const timeoutId = setTimeout( () => {
                observer.next();
                observer.complete();
              }, timeout);
              return () => clearTimeout(timeoutId);
          })),
          takeUntil(this._stopListening$)
        )
        .subscribe(() => 
        {
          if(!this._isModalConfirm){
            document.getElementById('btnModalSession')?.click();
            this._isModalConfirm = true;
            this._extendSession();
          }
        });
    });
  }

  private _closeSession() {
    this.manualStopListening();
    this._authS.logout();
  }

  manualStopListening() {
    if (this._userActivitySubscription) {
      this._userActivitySubscription.unsubscribe();
      this._userActivitySubscription = undefined;
    }
    this._stopListening$.next();
  }

  private _extendSession() {
    this._extendTimeout = setTimeout( () => {
      this._closeSession();
    }, ENV.sessionExtendLiveTime );
  }

  manualStartListening() {
    this._stopListening$.next();
    this.startWatching();
  }

  reviveListener(){
    this._isModalConfirm = false;
    this.manualStartListening();
    clearTimeout(this._extendTimeout);
  }

}
